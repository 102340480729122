import styled from 'styled-components';
import { Orders, Stat } from '@liqnft/candy-shop';
import { ConnectButton } from '@/components/ConnectButton';
import { useUserWallet } from '@/hooks/useUserWallet';
import { useShopStore } from '@/store/useShopStore';

const MarketplaceWithFilter: React.FC = () => {
  const userWallet = useUserWallet();
  const candyShop = useShopStore((s) => s.candyShop);
  if (!candyShop) return null;

  return (
    <DesContainer>
      <Stat
        candyShop={candyShop}
        title={'BONK Market'}
        description={'Sell NFT, get BONK!'}
      />
      <Orders
        wallet={userWallet}
        candyShop={candyShop}
        walletConnectComponent={<ConnectButton />}
        filters={true}
        filterSearch
      />
    </DesContainer>
  );
};

export default MarketplaceWithFilter;


const DesContainer = styled.div`
  width: 100%;
  .candy-container{
    max-width: none;
    .candy-orders-sort{
      .candy-search{
        display: none;
      }
    }
  }

  .candy-stat-component-container {
      display: none;
  }

  .candy-filter {
    color: #000;

    li:hover {
      color: #ff6c02;
    }

    .candy-search input {
      padding: 10px 4px 10px 24px;
      width: 100%;
      color: #000;
    }
    .candy-filter-title {
      display: none;
    }
  }

  .candy-orders-sort {
    display:none;

    li:hover {
      color: #ff6c02;
    }

    .candy-search input {
      padding: 10px 4px 10px 24px;
      width: 100%;
      color: #000;
    }
  }

  .candy-orders-content {

    .candy-container-list {
      padding: 10px 4px 10px 24px;
      width: 100%;
    }
    
    .candy-order:hover {
      padding: 0px;
      transform: scale(1.02);
      filter: brightness(0.9);
      transition-duration: 0.05s;
      width: 100%;
    }
  }
`;
